import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { Seo } from 'components';

import * as Elements from 'elements';
import * as Components from 'components';

/**
 * Лендинг департамента
 */

export default ({ data,
  data: {
    page: {
      frontmatter: { title, seo_title, full_title, og, description }
    }
  }
}) => {
  return (
    <>
      <Seo
        title={seo_title || full_title || title} og={og} description={description}
      />
      <MDXProvider components={{ ...Components, ...Elements }}>
        <MDXRenderer>{data.page.body}</MDXRenderer>
      </MDXProvider>
    </>
  );
};

export const pageQuery = graphql`
  query($id: String!) {
    page: mdx(id: {eq: $id}) {
      frontmatter {
        title
        full_title
        seo_title
        redirect
        og
        description
      }
      body
    }
  }
`;
